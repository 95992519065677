<template>
  <div id="app">
    <home-page></home-page>
  </div>
</template>

<script>
import homePage from './views/homePage/index.vue'

export default {
  name: 'App',
  components: {
  	homePage
  },
}
</script>

<style>
body,h1,h2,h3,h4,h5,h6,p {
		margin: 0;
		padding: 0;
		user-select: none;
	}

	img {
		display: block;
	}
</style>
