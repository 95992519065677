<template>
	<div class="body">
		<div class="hearderbox">
			<div class="hb_inner">
				<img class="hb_inner_l" src="../../static/logo.png" />
				<div class="hb_inner_r">
					<div class="hb_inner_r_l">
						<span class="span">首页</span>
						<span>爱采购</span>
						<span>直通车</span>
						<span>定制开发</span>
						<span>关于我们</span>
					</div>
					<div class="hb_inner_r_r">
						<img src="../../static/tel.png"/>
						<div>
							<div>咨询电话</div>
							<p>150 0268 5101</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<img class="bb_banner" src="../../static/banner.jpg" />
		<div class="title">定制开发服务</div>
		<p class="title_info">Customized development services</p>
		<div class="dzkffw">
			<div class="dzkffw_l">
				<img src="../../static/dzkffw.jpg"/>
				<h1>定制开发服务</h1>
				<p>我们拥有专业的技术开发团队，基于平台化的开发模式，为用户提供快速和高性价比的定制开发服务</p>
			</div>
			<div class="dzkffw_r">
				<div>
					<img src="../../static/fw_1.png"/>
					<p>软件开发</p>
				</div>
				<div>
					<img src="../../static/fw_2.png"/>
					<p>软件开发</p>
				</div>
				<div>
					<img src="../../static/fw_3.png"/>
					<p>软件开发</p>
				</div>
				<div>
					<img src="../../static/fw_4.png"/>
					<p>软件开发</p>
				</div>
				<div>
					<img src="../../static/fw_5.png"/>
					<p>软件开发</p>
				</div>
				<div>
					<img src="../../static/fw_6.png"/>
					<p>软件开发</p>
				</div>
			</div>
		</div>
		<div class="box1">
			<div class="b_box">
				<div class="b_box_t">
					<div class="b_box_t_l">
						<h1>您是否还在为这些定制开发问题发愁?</h1>
						<p>Are you still worrying about these custom development  issues?</p>
					</div>
					<div class="b_box_t_r"><i class="el-icon-question"></i>解决问题</div>
				</div>
				<div class="b_box_b">
					<div class="b_box_b_l">
						<img class="line" src="../../static/line.png"/>
						<img class="line" style="top: 208px;" src="../../static/line.png"/>
						<img class="line" style="top: 334px;" src="../../static/line.png"/>
						<div class="b_box_b_l_once">
							<img src="../../static/qt_1.png"/>
							<div>
								<h1>定制化</h1>
								<p>痛点把握不准确、解决方案匹配度低、服务模式不灵活</p>
							</div>
						</div>
						<div class="b_box_b_l_once">
							<img src="../../static/qt_1.png"/>
							<div>
								<h1>安全</h1>
								<p>数据安全有威胁、源代码保密性差、质量管控不健全</p>
							</div>
						</div>
						<div class="b_box_b_l_once">
							<img src="../../static/qt_1.png"/>
							<div>
								<h1>交付</h1>
								<p>交付不及时、成果Bug多、后期运维无保障</p>
							</div>
						</div>
						<div class="b_box_b_l_once">
							<img src="../../static/qt_1.png"/>
							<div>
								<h1>人员</h1>
								<p>员资质不合格、员储备不够、员管理混乱</p>
							</div>
						</div>
					</div>
					<img class="b_box_b_r" src="../../static/qt_img.png"/>
				</div>
			</div>
		</div>
		<div class="box2">
			<img src="../../static/1.jpg"/>
			<img src="../../static/2.jpg"/>
			<img src="../../static/3.jpg"/>
		</div>
		<div class="title">我们的优势</div>
		<p class="title_info">Our Advantages</p>
		<div class="box3">
			<div class="once">
				<img src="../../static/b3_1.png"/>
				<h1>敏捷开发</h1>
				<p>专业研发团队</p>
				<p>高效协作</p>
				<p>节点跟进</p>
				<p>快速迭代</p>
			</div>
			<div class="once">
				<img src="../../static/b3_2.png"/>
				<h1>完整交付</h1>
				<p>专业交付团队</p>
				<p>按时保值</p>
				<p>完整流程</p>
				<p>以客户为中心</p>
			</div>
			<div class="once">
				<img src="../../static/b3_3.png"/>
				<h1>敏捷开发</h1>
				<p>专业售后团队</p>
				<p>技术支持</p>
				<p>操作培训</p>
			</div>
		</div>
		<div class="box4">
			<div class="once">
				<h1>20+</h1>
				<p>100+上市公司的选择</p>
			</div>
			<div class="once">
				<h1>10+</h1>
				<p>12道内部执行流程</p>
			</div>
			<div class="once">
				<h1>12K+</h1>
				<p>95个检测标准</p>
			</div>
			<div class="once">
				<h1>100%</h1>
				<p>100%站内深度优化</p>
			</div>
		</div>
		<div class="box5">
			<div class="box5_inner">
				<div class="title">网站开发、设计、服务</div>
				<p class="title_info">多行业领域开发，专属定制，一站式服务</p>
				<div class="oncebox">
					<div class="once">
						<img src="../../static/b5_1.jpg"/>
						<div>
							<h1>展示性网站</h1>
							<p>Founder, Director</p>
						</div>
					</div>
					<div class="once">
						<img src="../../static/b5_2.jpg"/>
						<div>
							<h1>H5活动网站</h1>
							<p>Head Technician</p>
						</div>
					</div>
					<div class="once">
						<img src="../../static/b5_3.jpg"/>
						<div>
							<h1>行业门户</h1>
							<p>Technician</p>
						</div>
					</div>
					<div class="once">
						<img src="../../static/b5_4.jpg"/>
						<div>
							<h1>企业网站</h1>
							<p>Marketing Manager</p>
						</div>
					</div>
					<div class="once">
						<img src="../../static/b5_5.jpg"/>
						<div>
							<h1>电商网站</h1>
							<p>Sales Manager</p>
						</div>
					</div>
					<div class="once">
						<img src="../../static/b5_6.jpg"/>
						<div>
							<h1>营销网站</h1>
							<p>Support Assistant</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="title">全程创优更懂小程序</div>
		<p class="title_info">共享微信全生态 触手可及微信9亿用户</p>
		<div class="box6">
			<div class="once">
				<img src="../../static/b6_1.png"/>
				<h1>官网小程序</h1>
				<p>多种模式轻松搭建</p>
				<p>一键生成，快速上线</p>
			</div>
			<div class="once">
				<img src="../../static/b6_2.png"/>
				<h1>电商小程序</h1>
				<p>拖拽式小程序商城</p>
				<p>移动电商首选</p>
			</div>
			<div class="once">
				<img src="../../static/b6_3.png"/>
				<h1>预约小程序</h1>
				<p>小程序预约</p>
				<p>到店服务</p>
			</div>
			<div class="once">
				<img src="../../static/b6_4.png"/>
				<h1>多门店小程序</h1>
				<p>连锁超市、线下分销</p>
				<p>同城平台</p>
			</div>
			<div class="once">
				<img src="../../static/b6_5.png"/>
				<h1>外卖小程序</h1>
				<p>小程序线上下单</p>
				<p>线下送达</p>
			</div>
			<div class="once">
				<img src="../../static/b6_6.png"/>
				<h1>餐饮小程序</h1>
				<p>到店支付、排队点餐</p>
				<p>小票打印</p>
			</div>
			<div class="once">
				<img src="../../static/b6_7.png"/>
				<h1>销售小程序</h1>
				<p>小程序营销</p>
				<p>传播利器</p>
			</div>
			<div class="once">
				<img src="../../static/b6_8.png"/>
				<h1>分销小程序</h1>
				<p>二级返佣、无限裂变、</p>
				<p>低成本快速获客</p>
			</div>
		</div>
		<div class="box7">
			<img class="box7_l" src="../../static/b7_1.png"/>
			<img class="box7_r" src="../../static/b7_2.png"/>
			<div class="box7_fiexd">
				<div>
					<h1>强大功能，信心之选</h1>
					<p>强大的研发实力，安全稳定可靠的系统支持</p>
					<span>Contact Us</span>
				</div>
			</div>
		</div>
		<div class="box8">
			<div class="box8_inner">
				<p>@2019-2021 版权所有 陕西全程创优科技有限公司津ICP 备 13003374 号-8</p>
				<p>咨询电话: 400-960-3992 邮箱: service@cheexun.net</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			
			}
		}
	}
</script>

<style scoped lang="scss">
	.body{
		background: #F5F5F5;
		min-width: 1280px;
	}
	.hearderbox {
		min-width: 1280px;
		background: white;
	}

	.hb_inner {
		width: 1280px;
		height: 90px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.hb_inner_l {
		width: 150px;
	}

	.hb_inner_r{
		display: flex;
		align-items: center;
		&_l{
			display: flex;
			align-items: center;
			span{
				font-size: 16px;
				color: #1E1B1B;
				margin-right: 60px;
				cursor: pointer;
			}
			span:last-child{
				margin-right: 0;
			}
			.span{
				color: #4B93FF;
				font-weight: 600;
			}
		}
		&_r{
			display: flex;
			align-items: center;
			padding-left: 32px;
			margin-left: 52px;
			position: relative;
			img{
				width: 40px;
				height: 40px;
				margin-right: 14px;
			}
			div{
				div{
					font-size: 16px;
					color: #615F5F;
				}
				p{
					font-size: 18px;
					color: #1E1B1B;
				}
			}
		}
		&_r::after{
			content: '';
			width: 1px;
			height: 42px;
			background: #D8D7D7;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}

	.bb_banner {
		width: 100%;
	}
	.title {
		min-width: 1280px;
		margin: 0 auto 6px;
		font-size: 36px;
		color: #1E1B1B;
		text-align: center;
		font-weight: 600;
		padding-top: 68px;
	}

	.title_info {
		font-size: 24px;
		color: #1E1B1B;
		min-width: 1280px;
		margin: 0 auto 32px;
		text-align: center;
	}
	.dzkffw{
		width: 1280px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&_l{
			width: 624px;
			height:532px;
			background: white;
			padding-top: 64px;
			img{
				width:528px;
				height: 302px;
				margin: 0 auto;
			}
			h1{
				font-size: 32px;
				color: #1E1B1B;
				font-weight: 600;
				padding: 64px 48px 10px;
			}
			p{
				padding: 0 48px;
				font-size: 16px;
				color: #7B7C86;
				line-height: 30px;
			}
		}
		&_r{
			width: 624px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			div{
				width:296px;
				height:180px;
				background:white;
				margin-bottom: 28px;
				img{
					width:32px;
					height: 32px;
					margin: 0 auto;
					padding-top: 51px;
				}
				p{
					text-align: center;
					font-size: 15px;
					color: #1E1B1B;
					margin-top: 17px;
				}
			}
			div:nth-child(5),div:nth-child(6){
				margin-bottom: 0;
			}
		}
	}
	.box1{
		background: white;
		margin-top: 68px;
		padding: 90px 0 130px;
		.b_box{
			width: 1280px;
			margin: 0 auto;
			&_t{
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				&_l{
					width: 600px;
					h1{
						font-size: 36px;
						color: #1E1B1B;
						font-weight: 600;
						margin-bottom: 12px;
					}
					p{
						font-size: 24px;
						color: #7B7C86;
						line-height: 36px;
					}
				}
				&_r{
					display: flex;
					align-items: center;
					font-size: 20px;
					color: #4B93FF;
					font-weight: 600;
					i{
						font-size: 26px;
						margin-right: 10px;
					}
				}
			}
			&_b{
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 34px;
				&_l{
					flex: 1;
					position: relative;
					&_once{
						display: flex;
						align-items: flex-start;
						margin-bottom: 40px;
						img{
							width: 72px;
							height: 72px;
							margin-right: 24px;
						}
						div{
							flex: 1;
							h1{
								font-size: 24px;
								color: #1E1B1B;
								font-weight: 600;
								line-height: 72px;
							}
							p{
								font-size: 18px;
								color: #787676;
								position: relative;
								margin-top: -10px;
							}
						}
					}
					&_once:last-child{
						margin-bottom: 0;
					}
					.line{
						width: 2px;
						height: 40px;
						position: absolute;
						top: 82px;
						left: 35px;
					}
				}
				&_r{
					width: 578px;
					height: 476px;
				}
			}
		}
	}
	.box2{
		min-width: 1280px;
		display: flex;
		align-items: center;
		img{
			width: 33.3333333%;
		}
	}
	.box3{
		box-sizing: border-box;
		width: 1280px;
		margin: 0 auto;
		box-shadow: 11px 130px 100px 1px rgba(0,0,0,0.02);
		border: 1px solid #EBEBEB;
		border-radius: 0px 0px 0px 0px;
		background: #FFFFFF;
		display: flex;
		align-items: center;
		.once{
			width: 426px;
			height: 390px;
			position: relative;
			img{
				width: 64px;
				height: 64px;
				margin: 0 auto;
				padding-top: 56px;
			}
			h1{
				font-size: 30px;
				color: #000000;
				font-weight: 600;
				text-align: center;
				margin-top: 28px;
				margin-bottom: 20px;
			}
			p{
				font-size: 18px;
				color: #7B7C86;
				line-height: 36px;
				text-align: center;
			}
		}
		.once::after{
			content: '';
			width: 2px;
			height: 100%;
			background: #F2F2F2;
			position: absolute;
			top: 0;
			right: -1px;
		}
		.once:last-child::after{
			background: transparent;
		}
	}
	.box4{
		width: 1280px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		padding: 46px 0 70px;
		.once{
			width: 25%;
			h1{
				font-size: 58px;
				color: #1E1B1B;
				font-weight: 600;
			}
			p{
				font-size: 24px;
				color: #5E5C5C;
				margin-top: 10px;
			}
		}
	}
	.box5{
		background: white;
		padding-bottom: 100px;
		&_inner{
			width: 1280px;
			margin: 0 auto;
			.oncebox{
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				.once{
					width: 406px;
					margin-bottom: 48px;
					img{
						width: 406px;
						height: 316px;
					}
					div{
						height: 116px;
						background: #161616;
						text-align: center;
						h1{
							font-size: 24px;
							color: #FFFFFF;
							font-weight: normal;
							padding-top: 24px;
							padding-bottom: 8px;
						}
						p{
							font-size: 16px;
							color: #FFFFFF;
						}
					}
				}
			}
		}
	}
	.box6{
		width: 1280px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 88px;
		.once{
			width: 304px;
			background: white;
			margin-bottom: 20px;
			padding: 50px 0;
			img{
				width: 40px;
				height: 40px;
				margin: 0 auto;
				margin-bottom: 18px;
			}
			h1{
				font-size: 18px;
				color: #1E1B1B;
				font-weight: 600;
				text-align: center;
				margin-bottom: 10px;
			}
			p{
				font-size: 14px;
				color: #707070;
				text-align: center;
				line-height: 20px;
			}
		}
	}
	.box7{
		min-width: 1280px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		&_l{
			width: 49.1%;
		}
		&_r{
			width: 30.5%;
		}
		&_fiexd{
			width: 38%;
			height: 100%;
			background: #4B93FF;
			position: absolute;
			top: 0;
			left: 41.7%;
			div{
				width: calc(100% - 160px);
				position: absolute;
				top: 50%;
				left: 80px;
				transform: translateY(-50%);
				h1{
					font-size: 32px;
					color: #FFFFFF;
					font-weight: 600;
				}
				p{
					font-size: 20px;
					color: rgba(255, 255, 255, .9);
					margin-top: 18px;
				}
				span{
					display: block;
					width: 140px;
					height: 50px;
					text-align: center;
					line-height: 50px;
					border-radius: 12px;
					background: #1E1B1B;
					font-size: 18px;
					color: #FFFFFF;
					margin-top: 60px;
					cursor: pointer;
					transition: all .2s;
				}
				span:active{
					transform: scale(.92);
					opacity: .86;
				}
			}
		}
	}
	.box8{
		background: #1E1B1B;
		min-width: 1280px;
		&_inner{
			width: 1280px;
			margin: 0 auto;
			padding: 40px 0;
			p{
				font-size: 14px;
				color: #B1B1B1;
				text-align: center;
				line-height: 28px;
			}
		}
	}
</style>